import styled from "styled-components";

interface PageButtonProps {
    isActive: boolean
}

export const PaginationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
`

export const PageButton = styled.button<PageButtonProps>`
    cursor: pointer;
    ${({ isActive }) => isActive && `
        background: lightgrey;
        font-weight: bold;
    `};
    margin: 0 6px;
    border-radius: 6px;
    border: none;
    width: 30px;
`

export const MoveButton = styled.button`
    background: #A3C202;
    color: white;
    margin: 0 30px;
    border: none;
    padding: 4px 10px;
    border-radius: 6px;
    cursor: pointer;
`