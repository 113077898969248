import { MouseEvent } from 'react';
import { PaginationContainer, PageButton, MoveButton } from './styled';

interface PaginationProps {
    pageCount: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination = ({ pageCount, currentPage, onPageChange }: PaginationProps) => {
    const handlePageClick = (event: MouseEvent<HTMLButtonElement>, page: number) => {
        event.preventDefault();
        onPageChange(page);
    };

    const renderPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= pageCount; i++) {
            pages.push(
                <PageButton
                    key={i}
                    onClick={(e) => handlePageClick(e, i)}
                    isActive={i === currentPage}
                >
                    {i}
                </PageButton>
            );
        }
        return pages;
    };

    return (
        <PaginationContainer>
            <MoveButton
                onClick={(e) => handlePageClick(e, currentPage - 1)}
                disabled={currentPage === 1}
            >
                Previous
            </MoveButton>
            {renderPageNumbers()}
            <MoveButton
                onClick={(e) => handlePageClick(e, currentPage + 1)}
                disabled={currentPage === pageCount}
            >
                Next
            </MoveButton>
        </PaginationContainer>
    );
};

export default Pagination;
