
import DesignHubCard from '../components/DesignHubCard';
import ServiceHubCard from '../components/ServiceHubCard';
import CoreValuesCard from '../components/CoreValuesCard';
import PeoplePassion from '../components/PeoplePassion';
import TechSupport from '../components/TechSupport';
import History from '../components/History';
import ProductReview from '../components/ProductReview';
import SubHeaderCard from '../components/SubHeaderCard'
import MarketingCard from '../components/MarketingCard'
import MarketingOpportunities from '../components/MarketingOpportunities'
import Webinars from '../components/Webinars'
import OurServicesCard from '../components/OurSerivcesCard';
import { ExactCardProps } from '../types';

export const getExactCard = ({ card, item, showZoomImage }: ExactCardProps) => {
    switch (card) {
        case 'isDesignHub':
            return <DesignHubCard {...item} />;

        case 'isServiceHub':
            return <ServiceHubCard {...item} />;

        case 'isCoreValues':
            return <CoreValuesCard {...item} showZoomImage={showZoomImage} />;

        case 'isPeoplePassion':
            return <PeoplePassion item={item} showZoomImage={showZoomImage} />;

        case 'isTechSupport':
            return <TechSupport {...item} />;

        case 'isHistory':
            return <History {...item} />;

        case 'isProductReview':
            return <ProductReview {...item} />;

        case 'isSubHeader':
            return <SubHeaderCard {...item} />;

        case 'isMarketing':
            return <MarketingCard {...item} />;
            
        case 'isMarketingOpportunities':
            return <MarketingOpportunities {...item} />;
            
        case 'isWebinar':
            return <Webinars {...item} />;

        case 'OurServicesCard':
            return <OurServicesCard {...item} />;

        default:
            return <div>Default!</div>;
    }
};