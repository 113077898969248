import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  BreadCrumbsContainer,
  BreadCrumbsNav,
  BreadCrubsOrderList,
  BreadCrubsListItem,
  BreadCrubsSpanDivider
} from './styled';

interface BreadcrumbProps {
  pageTitle?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ pageTitle }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((item) => item);

  // Create breadcrumb schema for SEO
  const itemListElement = pathnames.map((value, index) => {
    const isLast = index === pathnames.length - 1;
    const name = isLast && pageTitle ? pageTitle : value.charAt(0).toUpperCase() + value.slice(1);
    return {
      '@type': 'ListItem',
      'position': index + 2, // position starts from 2 since Home is 1
      'name': name,
      'item': `${window.location.origin}/${pathnames.slice(0, index + 1).join('/')}`
    };
  });

  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        '@type': 'ListItem',
        'position': 1,
        'name': 'Home',
        'item': `${window.location.origin}/`
      },
      ...itemListElement
    ]
  };

  return (
    <BreadCrumbsContainer className="container">
      <BreadCrumbsNav aria-label="breadcrumb">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbSchema)}
          </script>
        </Helmet>
        <BreadCrubsOrderList>
          <BreadCrubsListItem>
            <Link to={`${window.location.origin}/`}>Home</Link>
          </BreadCrubsListItem>
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            const name = isLast && pageTitle ? pageTitle : value.charAt(0).toUpperCase() + value.slice(1);
            return (
              <React.Fragment key={to}>
                <BreadCrubsSpanDivider>/</BreadCrubsSpanDivider>
                <BreadCrubsListItem>
                  {isLast ? (
                    <span>{name}</span>
                  ) : (
                    <Link to={to}>{name}</Link>
                  )}
                </BreadCrubsListItem>
              </React.Fragment>
            );
          })}
        </BreadCrubsOrderList>
      </BreadCrumbsNav>
    </BreadCrumbsContainer>
  );
};

export default Breadcrumb;
