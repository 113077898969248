
import { useNavigate } from 'react-router-dom';
import {
    ProductBlock,
    ProductContent,
    ProductContentTitle,
    ProductContentInfo,
    ProductContentDescription,
    ProductImage,
    ProductMoreLink
} from './styled';
import { dateFormat } from 'utils/dateFormat';
import { FeaturedProduct } from 'store/types';
import { useState } from 'react';
import productMock from 'assets/images/mock-product.png';

function ProductReview({ id, brand, model, author, created_at, paragraphs, image, label_id }: FeaturedProduct) {
    const [imageLoaded, setImageLoaded] = useState(false);

    const navigate = useNavigate();

    const img = new Image();

    img.src = image[0]?.image;
    img.onload = function () {
        setImageLoaded(true)
    };

    return (
        <ProductBlock key={id}>
            <ProductContent>
                <ProductContentTitle to={`/product-reviews/${label_id}`}>
                    {brand} {model}
                </ProductContentTitle>
                <ProductContentInfo>
                    Writer: {author.name}
                </ProductContentInfo>
                <ProductContentInfo>
                    {dateFormat(created_at)}
                </ProductContentInfo>
                <ProductContentDescription>
                    {paragraphs[0].text}
                </ProductContentDescription>
                <ProductMoreLink onClick={() => navigate(`/product-reviews/${label_id}`)}>
                    Read more {'>'}
                </ProductMoreLink>
            </ProductContent>
            <ProductImage
                src={imageLoaded ? image[0]?.image : productMock}
                alt={image[0].name}
                placeholderSrc={image[0].image}
                style={{ width: '450px' }}
                loading="lazy"
                effect="blur"
                onClick={() => navigate(`/product-reviews/${label_id}`)}
            />
        </ProductBlock>
    );
}

export default ProductReview;
