import styled from 'styled-components';

export const OScard = styled.div`
border-radius: 20px;
width:100%;
height:240px;
position:relative;
`;

export const Image = styled.img`
    border-radius: 20px;
    width:100%;
    object-fit:cover;
    display:block;
    
    @media screen and (max-width: 600px) {
        
    }
`;

export const Title = styled.h3`
    color: #FFF;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    font-family: Poppins;

    position:absolute;
    inset:0;
    top:auto;
    bottom:0px;
    padding:15px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px 0px 20px 20px;
`;

export const Link = styled.a``;
