// if you need to work with backend locally, use 'http://localhost:8000'
// export const baseApiURL = 'http://localhost:8000';
export const baseApiURL = process.env.REACT_APP_BACKEND_URL || 'https://profound-tech.com';


export const endpoints = {
    sendEmail: `${baseApiURL}/send-email/`,
    subscribe: `${baseApiURL}/subscribe/`,
    products: (labelId?: string, page?: string, latestPost?: boolean) => `${baseApiURL}/products${labelId ? `/${labelId}/` : ''}${page ? `?page=${page}` : ''}${page ? '&' : '?'}latestPost=${latestPost}`,
    featuredProducts: `${baseApiURL}/featured-products`,
    surveyRoom: `${baseApiURL}/survey-room/`,
    signUp: `${baseApiURL}/sign-up/`,
    signIn: `${baseApiURL}/sign-in/`,
    tokenRefresh: `${baseApiURL}/token/refresh/`,
};
