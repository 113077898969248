import { OScard, Image, Title, Link } from './styled';

interface ImagesProps {
    image: string;
    alt: string;
    title: string;
    link: string;
}

function OurServicesCard({ image, alt, title, link }: ImagesProps) {
    return <OScard>
        <Link href={link || undefined}>
            <Image src={image} alt={alt} loading="lazy" width="328" height="240"/>
            <Title>{title}</Title>
        </Link>
    </OScard>
        
}

export default OurServicesCard;
