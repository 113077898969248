import styled from 'styled-components';

export const BreadCrumbsContainer = styled.div`
    font-family: 'Poppins';
    font-weight: 400;
    color: #0e4574;
`;
export const BreadCrumbsNav = styled.nav`
margin:10px 0;
`;

export const BreadCrubsOrderList = styled.ol`
    list-style-type: none;
    display: flex;
    gap: 5px;
`;

export const BreadCrubsListItem = styled.li`
a {
 text-decoration:none;
}
a.anchor-disable {
    cursor: not-allowed;
    pointer-events: none;
}
`;

export const BreadCrubsSpanDivider = styled.span`
    font-family: 'Poppins';
    font-weight: 400;
    color: #0e4574;
`;




